import React, { useState } from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function FormElementsInputAllBasicPreview(props) {
  const [state, setState] = useState({
    "id-b02": "",
    "id-l02": "",
    "id-b04": "",
    "id-l04": "",
    "id-b12": "",
    "id-l12": "",
    "id-b14": "1234567",
    "id-l14": "1234567",
    "id-b10": "John@Smith.",
    "id-l10": "John@Smith.",
    "id-b08": "John Smith",
    "id-l08": "John Smith",
    "id-b15": "",
    "id-l15": "",
  })

  const [showPassword, setShowPassword] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)

  const {
    FormElementsInputPlainBaseBasic,
    FormElementsInputPlainBaseHelperText,
    FormElementsInputPlainBaseLeadingIcon,
    FormElementsInputPlainBaseTrailingIcon,
    FormElementsInputPlainBaseInvalid,
    FormElementsInputPlainBaseRequired,
    FormElementsInputPlainBaseDisabled,
    FormElementsInputPlainBaseSearch,
  } = props

  const {
    FormElementsInputPlainBaseLgBasic,
    FormElementsInputPlainBaseLgHelperText,
    FormElementsInputPlainBaseLgLeadingIcon,
    FormElementsInputPlainBaseLgTrailingIcon,
    FormElementsInputPlainBaseLgInvalid,
    FormElementsInputPlainBaseLgRequired,
    FormElementsInputPlainBaseLgDisabled,
    FormElementsInputPlainBaseLgSearch,
  } = props

  const handleChange = evt => {
    const value = evt.target.value
    setState({
      ...state,
      [evt.target.name]: value,
    })
  }

  return (
    <>
      <div className="flex w-full flex-col justify-center gap-24 md:flex-row ">
        <div className="w-full">
          <h3 className="font-medium text-slate-500">Basic Input Base Size:</h3>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputPlainBaseBasic}
              componentName="FormElementsInputPlainBaseBasic"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain basic input  --> */}
              <div className="relative my-6">
                <input
                  id="id-b02"
                  type="text"
                  name="id-b02"
                  placeholder="your name"
                  value={state["id-b02"]}
                  className="peer relative h-10 w-full border-b border-slate-200 px-4 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-b02"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your name
                </label>
              </div>
              {/*    <!-- End Plain basic input  --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputPlainBaseHelperText}
              componentName="FormElementsInputPlainBaseHelperText"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain input with helper text  --> */}
              <div className="relative my-6">
                <input
                  id="id-b04"
                  type="text"
                  name="id-b04"
                  placeholder="your name"
                  value={state["id-b04"]}
                  className="peer relative h-10 w-full border-b border-slate-200 px-4 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-b04"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your name
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Plain input with helper text  --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputPlainBaseLeadingIcon}
              componentName="FormElementsInputPlainBaseLeadingIcon"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain leading icon input  --> */}
              <div className="relative my-6">
                <input
                  id="id-b12"
                  type="text"
                  name="id-b12"
                  placeholder="Your name"
                  value={state["id-b12"]}
                  className="peer relative h-10 w-full border-b border-slate-200 px-4 pl-12 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-b12"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:left-10 peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:left-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your name
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute left-4 top-2.5 h-5 w-5 stroke-slate-400 peer-disabled:cursor-not-allowed"
                  fill="none"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  aria-labelledby="title-1 description-1"
                  role="graphics-symbol"
                >
                  <title id="title-1">Check mark icon</title>
                  <desc id="description-1">Icon description here</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Plain leading icon input  --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputPlainBaseTrailingIcon}
              componentName="FormElementsInputPlainBaseTrailingIcon"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain trailing icon input  --> */}
              <div className="relative my-6">
                <input
                  id="id-b14"
                  type={showPassword ? "text" : "password"}
                  value={state["id-b14"]}
                  name="id-b14"
                  placeholder="your name"
                  className="peer relative h-10 w-full border-b border-slate-200 px-4 pr-12 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-b14"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your password
                </label>
                {showPassword ? (
                  <svg
                    onClick={() => setShowPassword(!showPassword)}
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute right-4 top-2.5 h-5 w-5 cursor-pointer stroke-slate-400 peer-disabled:cursor-not-allowed"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    aria-labelledby="title-2 description-2"
                    role="graphics-symbol"
                  >
                    <title id="title-2">Check mark icon</title>
                    <desc id="description-2">Icon description here</desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                  </svg>
                ) : (
                  <svg
                    onClick={() => setShowPassword(!showPassword)}
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute right-4 top-2.5 h-5 w-5 cursor-pointer stroke-slate-400 peer-disabled:cursor-not-allowed"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    aria-labelledby="title-2d description-2d"
                    role="graphics-symbol"
                  >
                    <title id="title-2d">Check mark icon</title>
                    <desc id="description-2d">Icon description here</desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                    />
                  </svg>
                )}
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Plain trailing icon input  --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputPlainBaseInvalid}
              componentName="FormElementsInputPlainBaseInvalid"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain invalid input  --> */}
              <div className="relative my-6">
                <input
                  id="id-b10"
                  type="email"
                  name="id-b10"
                  placeholder="Your email"
                  value={state["id-b10"]}
                  className="peer relative h-10 w-full border-b border-slate-200 px-4 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-b10"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your email
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Plain invalid input  --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputPlainBaseRequired}
              componentName="FormElementsInputPlainBaseRequired"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain required input  --> */}
              <div className="relative my-6">
                <input
                  id="id-b08"
                  type="text"
                  name="id-b08"
                  required
                  value={state["id-b08"]}
                  className="peer relative h-10 w-full border-b border-slate-200 px-4 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-b08"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your name
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Plain required input  --> */}
            </CopyComponent>
          </div>

          <div className="relative my-6">
            <div className="mb-10 h-24">
              <CopyComponent
                copyToClipboardCode={FormElementsInputPlainBaseDisabled}
                componentName="FormElementsInputPlainBaseDisabled"
                extraClasses="copy-block-container"
              >
                {/*    <!-- Component: Plain disabled input  --> */}
                <input
                  id="id-b06"
                  type="text"
                  name="id-b06"
                  disabled
                  value={state["id-b06"]}
                  placeholder="your name"
                  className="peer relative h-10 w-full border-b border-slate-200 px-4 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                />
                <label
                  htmlFor="id-b06"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your name
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
                {/*    <!-- End Plain disabled input  --> */}
              </CopyComponent>
            </div>
          </div>

          <div className="h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputPlainBaseSearch}
              componentName="FormElementsInputPlainBaseSearch"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain search input  --> */}
              <div className="relative my-6">
                <input
                  id="id-b15"
                  type="text"
                  name="id-b15"
                  placeholder="Search here"
                  value={state["id-b15"]}
                  className="peer relative h-10 w-full border-b border-slate-200 px-4 pr-12 text-sm text-slate-500 outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute right-4 top-2.5 h-5 w-5 cursor-pointer stroke-slate-400 peer-disabled:cursor-not-allowed"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  aria-hidden="true"
                  aria-labelledby="title-10 description-10"
                  role="graphics-symbol"
                >
                  <title id="title-10">Search icon</title>
                  <desc id="description-10">Icon description here</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </div>
              {/*    <!-- End Plain search input  --> */}
            </CopyComponent>
          </div>
        </div>

        <div className="w-full">
          <h3 className="font-medium text-slate-500">
            Basic Input Large Size:
          </h3>
          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputPlainBaseLgBasic}
              componentName="FormElementsInputPlainBaseLgBasic"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain large basic input --> */}
              <div className="relative my-6">
                <input
                  id="id-l02"
                  type="text"
                  name="id-l02"
                  placeholder="your name"
                  value={state["id-l02"]}
                  className="peer relative h-12 w-full border-b border-slate-200 px-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-l02"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your name
                </label>
              </div>
              {/*    <!-- End Plain large basic input --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputPlainBaseLgHelperText}
              componentName="FormElementsInputPlainBaseLgHelperText"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain large input with helper text --> */}
              <div className="relative my-6">
                <input
                  id="id-l04"
                  type="text"
                  name="id-l04"
                  placeholder="your name"
                  value={state["id-l04"]}
                  className="peer relative h-12 w-full border-b border-slate-200 px-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-l04"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your name
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Plain large input with helper text --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputPlainBaseLgLeadingIcon}
              componentName="FormElementsInputPlainBaseLgLeadingIcon"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain large input with leading icon  --> */}
              <div className="relative my-6">
                <input
                  id="id-l12"
                  type="text"
                  name="id-l12"
                  placeholder="your name"
                  value={state["id-l12"]}
                  className="peer relative h-12 w-full border-b border-slate-200 px-4 pl-12 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-l12"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:left-10 peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:left-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your name
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute left-4 top-3 h-6 w-6 stroke-slate-400 peer-disabled:cursor-not-allowed"
                  fill="none"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  aria-labelledby="title-5 description-5"
                  role="graphics-symbol"
                >
                  <title id="title-5">Check mark icon</title>
                  <desc id="description-5">Icon description here</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Plain large input with leading icon --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputPlainBaseLgTrailingIcon}
              componentName="FormElementsInputPlainBaseLgTrailingIcon"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain large input with trailing icon  --> */}
              <div className="relative my-6">
                <input
                  id="id-l14"
                  type={showPassword2 ? "text" : "password"}
                  name="id-l14"
                  value={state["id-l14"]}
                  placeholder="your password"
                  className="peer relative h-12 w-full border-b border-slate-200 px-4 pr-12 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-l14"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your password
                </label>
                {showPassword2 ? (
                  <svg
                    onClick={() => setShowPassword2(!showPassword2)}
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute right-4 top-3 h-6 w-6 cursor-pointer stroke-slate-400 peer-disabled:cursor-not-allowed"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    aria-labelledby="title-6 description-6"
                    role="graphics-symbol"
                  >
                    <title id="title-6">Check mark icon</title>
                    <desc id="description-6">Icon description here</desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                  </svg>
                ) : (
                  <svg
                    onClick={() => setShowPassword2(!showPassword2)}
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute right-4 top-3 h-6 w-6 cursor-pointer stroke-slate-400 peer-disabled:cursor-not-allowed"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    aria-labelledby="title-6d description-6d"
                    role="graphics-symbol"
                  >
                    <title id="title-6d">Check mark icon</title>
                    <desc id="description-6d">Icon description here</desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                    />
                  </svg>
                )}
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Plain large input with trailing icon --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputPlainBaseLgInvalid}
              componentName="FormElementsInputPlainBaseLgInvalid"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain large invalid input  --> */}
              <div className="relative my-6">
                <input
                  id="id-l10"
                  type="email"
                  name="id-l10"
                  placeholder="Your email"
                  value={state["id-l10"]}
                  className="peer relative h-12 w-full border-b border-slate-200 px-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-l10"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your email
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Plain large invalid input --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputPlainBaseLgRequired}
              componentName="FormElementsInputPlainBaseLgRequired"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain large required input  --> */}
              <div className="relative my-6">
                <input
                  id="id-l08"
                  type="text"
                  name="id-l08"
                  placeholder="your name"
                  required
                  value={state["id-l08"]}
                  className="peer relative h-12 w-full border-b border-slate-200 px-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-l08"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your name
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Plain large required input --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputPlainBaseLgDisabled}
              componentName="FormElementsInputPlainBaseLgDisabled"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain large disabled input  --> */}
              <div className="relative my-6">
                <input
                  id="id-l06"
                  type="text"
                  name="id-l06"
                  placeholder="your name"
                  disabled
                  value={state["id-l06"]}
                  className="peer relative h-12 w-full border-b border-slate-200 px-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-l06"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your name
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Plain large disabled input --> */}
            </CopyComponent>
          </div>

          <div className="h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputPlainBaseLgSearch}
              componentName="FormElementsInputPlainBaseLgSearch"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain large search input --> */}
              <div className="relative my-6">
                <input
                  id="id-l15"
                  type="text"
                  name="id-l15"
                  placeholder="Search here"
                  value={state["id-l15"]}
                  className="peer relative h-12 w-full border-b border-slate-200 px-4 pr-12 text-slate-500 outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute right-4 top-3 h-6 w-6 cursor-pointer stroke-slate-400 peer-disabled:cursor-not-allowed"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  aria-hidden="true"
                  aria-labelledby="title-11 description-11"
                  role="graphics-symbol"
                >
                  <title id="title-11">Search icon</title>
                  <desc id="description-11">Icon description here</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </div>
              {/*    <!-- End Plain large search input --> */}
            </CopyComponent>
          </div>
        </div>
      </div>
    </>
  )
}
