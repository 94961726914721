import React, { useState } from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function FormElementsInputAllRoundedPreview(props) {
  const [state, setState] = useState({
    "id-b01": "",
    "id-l01": "",
    "id-b03": "",
    "id-l03": "",
    "id-11": "",
    "id-l11": "",
    "id-b13": "123456",
    "id-l13": "123456",
    "id-b09": "John@Smith.",
    "id-l09": "John@Smith.",
    "id-b07": "John Smith",
    "id-l07": "John Smith",
    "id-b16": "",
    "id-l16": "",
  })

  const [showPassword, setShowPassword] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)

  const {
    FormElementsInputRoundedBaseBasic,
    FormElementsInputRoundedBaseHelperText,
    FormElementsInputRoundedBaseLeadingIcon,
    FormElementsInputRoundedBaseTrailingIcon,
    FormElementsInputRoundedBaseInvalid,
    FormElementsInputRoundedBaseRequired,
    FormElementsInputRoundedBaseDisabled,
    FormElementsInputRoundedBaseSearch,
  } = props

  const {
    FormElementsInputRoundedBaseLgBasic,
    FormElementsInputRoundedBaseLgHelperText,
    FormElementsInputRoundedBaseLgLeadingIcon,
    FormElementsInputRoundedBaseLgTrailingIcon,
    FormElementsInputRoundedBaseLgInvalid,
    FormElementsInputRoundedBaseLgRequired,
    FormElementsInputRoundedBaseLgDisabled,
    FormElementsInputRoundedBaseLgSearch,
  } = props

  const handleChange = evt => {
    const value = evt.target.value
    setState({
      ...state,
      [evt.target.name]: value,
    })
  }

  return (
    <>
      <div className="flex w-full flex-col justify-center gap-24 md:flex-row ">
        <div className="w-full">
          <h3 className="font-medium text-slate-500">
            Outlined Input Base Size:
          </h3>
          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputRoundedBaseBasic}
              componentName="FormElementsInputRoundedBaseBasic"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded basic input  --> */}
              <div className="relative my-6">
                <input
                  id="id-01"
                  type="text"
                  name="id-01"
                  placeholder="your name"
                  value={state["id-01"]}
                  className="peer relative h-10 w-full rounded border border-slate-200 px-4 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-01"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your name
                </label>
              </div>
              {/*    <!-- End Rounded basic input  --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputRoundedBaseHelperText}
              componentName="FormElementsInputRoundedBaseHelperText"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded input with helper text --> */}
              <div className="relative my-6">
                <input
                  id="id-b03"
                  type="text"
                  name="id-b03"
                  placeholder="your name"
                  value={state["id-b03"]}
                  className="peer relative h-10 w-full rounded border border-slate-200 px-4 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-b03"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your name
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Rounded input with helper text --> */}
            </CopyComponent>
          </div>
          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputRoundedBaseLeadingIcon}
              componentName="FormElementsInputRoundedBaseLeadingIcon"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded input with leading icon--> */}
              <div className="relative my-6">
                <input
                  id="id-11"
                  type="text"
                  name="id-11"
                  placeholder="your name"
                  value={state["id-11"]}
                  className="peer relative h-10 w-full rounded border border-slate-200 px-4 pl-12 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-11"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:left-10 peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:left-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Text field
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute left-4 top-2.5 h-5 w-5 stroke-slate-400 peer-disabled:cursor-not-allowed"
                  fill="none"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  aria-labelledby="title-3 description-3"
                  role="graphics-symbol"
                >
                  <title id="title-3">Check mark icon</title>
                  <desc id="description-3">icon description here</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Rounded input with leading icon--> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputRoundedBaseTrailingIcon}
              componentName="FormElementsInputRoundedBaseTrailingIcon"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded input with trailing icon --> */}
              <div className="relative my-6">
                <input
                  id="id-b13"
                  type={showPassword ? "text" : "password"}
                  name="id-b13"
                  placeholder="your password"
                  className="peer relative h-10 w-full rounded border border-slate-200 px-4 pr-12 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                  value={state["id-b13"]}
                />
                <label
                  htmlFor="id-b13"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your password
                </label>
                {showPassword ? (
                  <svg
                    onClick={() => setShowPassword(!showPassword)}
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute right-4 top-2.5 h-5 w-5 cursor-pointer stroke-slate-400 peer-disabled:cursor-not-allowed"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    aria-labelledby="title-4 description-4"
                    role="graphics-symbol"
                  >
                    <title id="title-4">Check mark icon</title>
                    <desc id="description-4">Icon description here</desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                  </svg>
                ) : (
                  <svg
                    onClick={() => setShowPassword(!showPassword)}
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute right-4 top-2.5 h-5 w-5 cursor-pointer stroke-slate-400 peer-disabled:cursor-not-allowed"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    aria-labelledby="title-4d description-4d"
                    role="graphics-symbol"
                  >
                    <title id="title-4d">Check mark icon</title>
                    <desc id="description-4d">Icon description here</desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                    />
                  </svg>
                )}
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Rounded input with trailing icon --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputRoundedBaseInvalid}
              componentName="FormElementsInputRoundedBaseInvalid"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded invalid input --> */}
              <div className="relative my-6">
                <input
                  id="id-b09"
                  type="email"
                  name="id-b09"
                  placeholder="Your email"
                  value={state["id-b09"]}
                  className="peer relative h-10 w-full rounded border border-slate-200 px-4 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-b09"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your email
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Rounded invalid input --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputRoundedBaseRequired}
              componentName="FormElementsInputRoundedBaseRequired"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded required input --> */}
              <div className="relative my-6">
                <input
                  id="id-b07"
                  type="text"
                  name="id-b07"
                  required
                  value={state["id-b07"]}
                  className="peer relative h-10 w-full rounded border border-slate-200 px-4 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-b07"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your name
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Rounded required input --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputRoundedBaseDisabled}
              componentName="FormElementsInputRoundedBaseDisabled"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded disabled input --> */}
              <div className="relative my-6">
                <input
                  id="id-b05"
                  type="text"
                  name="id-b05"
                  disabled
                  value={state["id-b05"]}
                  placeholder="your name"
                  className="peer relative h-10 w-full rounded border border-slate-200 px-4 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                />
                <label
                  htmlFor="id-b05"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your name
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Rounded disabled input --> */}
            </CopyComponent>
          </div>

          <div className="h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputRoundedBaseSearch}
              componentName="FormElementsInputRoundedBaseSearch"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded search input  --> */}
              <div className="relative my-6">
                <input
                  id="id-b16"
                  type="text"
                  name="id-b16"
                  placeholder="Search here"
                  value={state["id-b16"]}
                  className="peer relative h-10 w-full rounded border border-slate-200 px-4 pr-12 text-sm text-slate-500 outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute right-4 top-2.5 h-5 w-5 cursor-pointer stroke-slate-400 peer-disabled:cursor-not-allowed"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  aria-hidden="true"
                  aria-labelledby="title-12 description-12"
                  role="graphics-symbol"
                >
                  <title id="title-12">Search icon</title>
                  <desc id="description-12">Icon description here</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </div>
              {/*    <!-- End Rounded search input  --> */}
            </CopyComponent>
          </div>
        </div>

        {/*  <!-- LARGE ROUNDED  --> */}

        <div className="w-full">
          <h3 className="font-medium text-slate-500">
            Outlined Input Large Size:
          </h3>
          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputRoundedBaseLgBasic}
              componentName="FormElementsInputRoundedBaseLgBasic"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded large basic input --> */}
              <div className="relative my-6">
                <input
                  id="id-l01"
                  type="text"
                  name="id-l01"
                  placeholder="your name"
                  value={state["id-l01"]}
                  className="peer relative h-12 w-full rounded border border-slate-200 px-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-l01"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your name
                </label>
              </div>
              {/*    <!-- End Rounded large basic input--> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputRoundedBaseLgHelperText}
              componentName="FormElementsInputRoundedBaseLgHelperText"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded large input with helper text --> */}
              <div className="relative my-6">
                <input
                  id="id-l03"
                  type="text"
                  name="id-l03"
                  placeholder="your name"
                  value={state["id-l03"]}
                  className="peer relative h-12 w-full rounded border border-slate-200 px-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-l03"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your name
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Rounded large input with helper text--> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputRoundedBaseLgLeadingIcon}
              componentName="FormElementsInputRoundedBaseLgLeadingIcon"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded large input with leading icon --> */}
              <div className="relative my-6">
                <input
                  id="id-l11"
                  type="text"
                  name="id-l11"
                  value={state["id-l11"]}
                  placeholder="your name"
                  className="peer relative h-12 w-full rounded border border-slate-200 px-4 pl-12 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-l11"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:left-10 peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:left-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your name
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute left-4 top-3 h-6 w-6 stroke-slate-400 peer-disabled:cursor-not-allowed"
                  fill="none"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  aria-labelledby="title-7 description-7"
                  role="graphics-symbol"
                >
                  <title id="title-7">Check mark icon</title>
                  <desc id="description-7">Icon description here</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Rounded large input with leading icon --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputRoundedBaseLgTrailingIcon}
              componentName="FormElementsInputRoundedBaseLgTrailingIcon"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded large input with trailing icon--> */}
              <div className="relative my-6">
                <input
                  id="id-l13"
                  type={showPassword2 ? "text" : "password"}
                  name="id-l13"
                  placeholder="your password"
                  className="peer relative h-12 w-full rounded border border-slate-200 px-4 pr-12 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  value={state["id-l13"]}
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-l13"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your password
                </label>
                {showPassword2 ? (
                  <svg
                    onClick={() => setShowPassword2(!showPassword2)}
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute right-4 top-3 h-6 w-6 cursor-pointer stroke-slate-400 peer-disabled:cursor-not-allowed"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    aria-labelledby="title-8 description-8"
                    role="graphics-symbol"
                  >
                    <title id="title-8">Check mark icon</title>
                    <desc id="description-8">Icon description here</desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                  </svg>
                ) : (
                  <svg
                    onClick={() => setShowPassword2(!showPassword2)}
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute right-4 top-3 h-6 w-6 cursor-pointer stroke-slate-400 peer-disabled:cursor-not-allowed"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    aria-labelledby="title-8d description-8d"
                    role="graphics-symbol"
                  >
                    <title id="title-8">Check mark icon</title>
                    <desc id="description-8">Icon description here</desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                    />
                  </svg>
                )}
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Rounded large input with trailing icon--> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputRoundedBaseLgInvalid}
              componentName="FormElementsInputRoundedBaseLgInvalid"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded large invalid input --> */}
              <div className="relative my-6">
                <input
                  id="id-l09"
                  type="email"
                  name="id-l09"
                  placeholder="Your email"
                  value={state["id-l09"]}
                  className="peer relative h-12 w-full rounded border border-slate-200 px-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-l09"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your email
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Rounded large invalid input --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputRoundedBaseLgRequired}
              componentName="FormElementsInputRoundedBaseLgRequired"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded large required input --> */}
              <div className="relative my-6">
                <input
                  id="id-l07"
                  type="text"
                  name="id-l07"
                  required
                  placeholder="your name"
                  value={state["id-l07"]}
                  className="peer relative h-12 w-full rounded border border-slate-200 px-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <label
                  htmlFor="id-l07"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your name
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Rounded large required input--> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputRoundedBaseLgDisabled}
              componentName="FormElementsInputRoundedBaseLgDisabled"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded large disabled input --> */}
              <div className="relative my-6">
                <input
                  id="id-l05"
                  type="text"
                  name="id-l05"
                  disabled
                  value={state["id-l05"]}
                  placeholder="your name"
                  className="peer relative h-12 w-full rounded border border-slate-200 px-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                />
                <label
                  htmlFor="id-l05"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Your name
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text</span>
                  <span className="text-slate-500">1/10</span>
                </small>
              </div>
              {/*    <!-- End Rounded large disabled input--> */}
            </CopyComponent>
          </div>

          <div className="h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsInputRoundedBaseLgSearch}
              componentName="FormElementsInputRoundedBaseLgSearch"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded search input  --> */}
              <div className="relative my-6">
                <input
                  id="id-l16"
                  type="text"
                  name="id-l16"
                  placeholder="Search here"
                  value={state["id-l16"]}
                  className="peer relative h-12 w-full rounded border border-slate-200 px-4 pr-12 text-slate-500 outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                  onChange={handleChange}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute right-4 top-3 h-6 w-6 cursor-pointer stroke-slate-400 peer-disabled:cursor-not-allowed"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  aria-hidden="true"
                  aria-labelledby="title-9 description-9"
                  role="graphics-symbol"
                >
                  <title id="title-9">Search icon</title>
                  <desc id="description-9">Icon description here</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </div>
              {/*    <!-- End Rounded search input  --> */}
            </CopyComponent>
          </div>
        </div>
      </div>
    </>
  )
}
