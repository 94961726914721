import React, { useState } from "react"

// Components
import Seo from "../../../../components/seo"
import SidebarLayout from "../../../../layouts/SidebarLayout"
import Section from "../../../../components/section/Section"
import ContentNavigation from "../../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../../components/preview"

import FormElementsInputAllBasicPreview from "../../../../library/components/form-elements/input/react/_preview/basic"
import FormElementsInputAllRoundedPreview from "../../../../library/components/form-elements/input/react/_preview/rounded"

import HeroInputComponent from "../../../../library/components/form-elements/input/react/_preview/heroInputComponent"

// Plain Input Fields

// Base
const FormElementsInputPlainBaseBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/plain/base/basic.jsx")
const FormElementsInputPlainBaseBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/plain/base/basic.html")

const FormElementsInputPlainBaseHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/plain/base/helper_text.jsx")
const FormElementsInputPlainBaseHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/plain/base/helper_text.html")

const FormElementsInputPlainBaseLeadingIconJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/plain/base/leading_icon.jsx")
const FormElementsInputPlainBaseLeadingIconHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/plain/base/leading_icon.html")

const FormElementsInputPlainBaseTrailingIconJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/plain/base/trailing_icon.jsx")
const FormElementsInputPlainBaseTrailingIconHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/plain/base/trailing_icon.html")

const FormElementsInputPlainBaseInvalidJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/plain/base/invalid.jsx")
const FormElementsInputPlainBaseInvalidHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/plain/base/invalid.html")

const FormElementsInputPlainBaseRequiredJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/plain/base/required.jsx")
const FormElementsInputPlainBaseRequiredHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/plain/base/required.html")

const FormElementsInputPlainBaseDisabledJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/plain/base/disabled.jsx")
const FormElementsInputPlainBaseDisabledHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/plain/base/disabled.html")

const FormElementsInputPlainBaseSearchJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/plain/base/search.jsx")
const FormElementsInputPlainBaseSearchHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/plain/base/search.html")

// Lg
const FormElementsInputPlainBaseLgBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/plain/lg/basic.jsx")
const FormElementsInputPlainBaseLgBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/plain/lg/basic.html")

const FormElementsInputPlainBaseLgHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/plain/lg/helper_text.jsx")
const FormElementsInputPlainBaseLgHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/plain/lg/helper_text.html")

const FormElementsInputPlainBaseLgLeadingIconJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/plain/lg/leading_icon.jsx")
const FormElementsInputPlainBaseLgLeadingIconHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/plain/lg/leading_icon.html")

const FormElementsInputPlainBaseLgTrailingIconJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/plain/lg/trailing_icon.jsx")
const FormElementsInputPlainBaseLgTrailingIconHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/plain/lg/trailing_icon.html")

const FormElementsInputPlainBaseLgInvalidJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/plain/lg/invalid.jsx")
const FormElementsInputPlainBaseLgInvalidHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/plain/lg/invalid.html")

const FormElementsInputPlainBaseLgRequiredJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/plain/lg/required.jsx")
const FormElementsInputPlainBaseLgRequiredHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/plain/lg/required.html")

const FormElementsInputPlainBaseLgDisabledJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/plain/lg/disabled.jsx")
const FormElementsInputPlainBaseLgDisabledHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/plain/lg/disabled.html")

const FormElementsInputPlainBaseLgSearchJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/plain/lg/search.jsx")
const FormElementsInputPlainBaseLgSearchHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/plain/lg/search.html")

// Rounded Input Fields
// Base
const FormElementsInputRoundedBaseBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/rounded/base/basic.jsx")
const FormElementsInputRoundedBaseBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/rounded/base/basic.html")

const FormElementsInputRoundedBaseHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/rounded/base/helper_text.jsx")
const FormElementsInputRoundedBaseHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/rounded/base/helper_text.html")

const FormElementsInputRoundedBaseLeadingIconJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/rounded/base/leading_icon.jsx")
const FormElementsInputRoundedBaseLeadingIconHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/rounded/base/leading_icon.html")

const FormElementsInputRoundedBaseTrailingIconJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/rounded/base/trailing_icon.jsx")
const FormElementsInputRoundedBaseTrailingIconHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/rounded/base/trailing_icon.html")

const FormElementsInputRoundedBaseInvalidJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/rounded/base/invalid.jsx")
const FormElementsInputRoundedBaseInvalidHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/rounded/base/invalid.html")

const FormElementsInputRoundedBaseRequiredJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/rounded/base/required.jsx")
const FormElementsInputRoundedBaseRequiredHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/rounded/base/required.html")

const FormElementsInputRoundedBaseDisabledJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/rounded/base/disabled.jsx")
const FormElementsInputRoundedBaseDisabledHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/rounded/base/disabled.html")

const FormElementsInputRoundedBaseSearchJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/rounded/base/search.jsx")
const FormElementsInputRoundedBaseSearchHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/rounded/base/search.html")

// Lg
const FormElementsInputRoundedBaseLgBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/rounded/lg/basic.jsx")
const FormElementsInputRoundedBaseLgBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/rounded/lg/basic.html")

const FormElementsInputRoundedBaseLgHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/rounded/lg/helper_text.jsx")
const FormElementsInputRoundedBaseLgHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/rounded/lg/helper_text.html")

const FormElementsInputRoundedBaseLgLeadingIconJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/rounded/lg/leading_icon.jsx")
const FormElementsInputRoundedBaseLgLeadingIconHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/rounded/lg/leading_icon.html")

const FormElementsInputRoundedBaseLgTrailingIconJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/rounded/lg/trailing_icon.jsx")
const FormElementsInputRoundedBaseLgTrailingIconHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/rounded/lg/trailing_icon.html")

const FormElementsInputRoundedBaseLgInvalidJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/rounded/lg/invalid.jsx")
const FormElementsInputRoundedBaseLgInvalidHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/rounded/lg/invalid.html")

const FormElementsInputRoundedBaseLgRequiredJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/rounded/lg/required.jsx")
const FormElementsInputRoundedBaseLgRequiredHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/rounded/lg/required.html")

const FormElementsInputRoundedBaseLgDisabledJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/rounded/lg/disabled.jsx")
const FormElementsInputRoundedBaseLgDisabledHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/rounded/lg/disabled.html")

const FormElementsInputRoundedBaseLgSearchJsx = require("!!raw-loader!../../../../library/components/form-elements/input/react/rounded/lg/search.jsx")
const FormElementsInputRoundedBaseLgSearchHTML = require("!!raw-loader!../../../../library/components/form-elements/input/html/rounded/lg/search.html")

export default function FormElementInputGroupsPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "FormElementsInputAllBasic",
      title: "Basic input",
      active_tab: 1,
    },
    {
      component_name: "FormElementsInputAllRounded",
      title: "Outline",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Input Groups - WindUI Component Library"
        ogtitle="Tailwind CSS Input Groups - WindUI Component Library"
        description="Input Group components are used to create interactive controls for web-based forms in order to accept data from the user. Built with Tailwind CSS by WindUI."
        ogdescription="Input Group components are used to create interactive controls for web-based forms in order to accept data from the user. Built with Tailwind CSS by WindUI."
        url="components/form-elements/input-groups/"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Input Groups, Input Groups, Input Group Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Input Groups</h1>
          <p>
            The input element is used to create interactive controls for
            web-based forms in order to accept data from the user; a wide
            variety of types of input data and control widgets are available,
            depending on the device and user agent. The element is one of the
            most powerful and complex in all of HTML due to the sheer number of
            combinations of input types and attributes.
          </p>

          <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16">
            <section
              className="not-prose max-w-full"
              aria-multiselectable="false"
            >
              <HeroInputComponent />
            </section>
          </div>
        </Section>
        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* Input Basic Section */}
          <h3 id="FormElementsInputAllBasic">Basic Input</h3>

          <PreviewBlock
            id="FormElementsInputAllBasic"
            HtmlComponent={FormElementsInputPlainBaseBasicHTML.default}
            JsxComponent={FormElementsInputPlainBaseBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <FormElementsInputAllBasicPreview
                FormElementsInputPlainBaseBasic={
                  activeTabs[0].active_tab === 1
                    ? FormElementsInputPlainBaseBasicHTML.default
                    : FormElementsInputPlainBaseBasicJsx.default
                }
                FormElementsInputPlainBaseLgBasic={
                  activeTabs[0].active_tab === 1
                    ? FormElementsInputPlainBaseLgBasicHTML.default
                    : FormElementsInputPlainBaseLgBasicJsx.default
                }
                FormElementsInputPlainBaseHelperText={
                  activeTabs[0].active_tab === 1
                    ? FormElementsInputPlainBaseHelperTextHTML.default
                    : FormElementsInputPlainBaseHelperTextJsx.default
                }
                FormElementsInputPlainBaseLgHelperText={
                  activeTabs[0].active_tab === 1
                    ? FormElementsInputPlainBaseLgHelperTextHTML.default
                    : FormElementsInputPlainBaseLgHelperTextJsx.default
                }
                FormElementsInputPlainBaseLeadingIcon={
                  activeTabs[0].active_tab === 1
                    ? FormElementsInputPlainBaseLeadingIconHTML.default
                    : FormElementsInputPlainBaseLeadingIconJsx.default
                }
                FormElementsInputPlainBaseLgLeadingIcon={
                  activeTabs[0].active_tab === 1
                    ? FormElementsInputPlainBaseLgLeadingIconHTML.default
                    : FormElementsInputPlainBaseLgLeadingIconJsx.default
                }
                FormElementsInputPlainBaseTrailingIcon={
                  activeTabs[0].active_tab === 1
                    ? FormElementsInputPlainBaseTrailingIconHTML.default
                    : FormElementsInputPlainBaseTrailingIconJsx.default
                }
                FormElementsInputPlainBaseLgTrailingIcon={
                  activeTabs[0].active_tab === 1
                    ? FormElementsInputPlainBaseLgTrailingIconHTML.default
                    : FormElementsInputPlainBaseLgTrailingIconJsx.default
                }
                FormElementsInputPlainBaseInvalid={
                  activeTabs[0].active_tab === 1
                    ? FormElementsInputPlainBaseInvalidHTML.default
                    : FormElementsInputPlainBaseInvalidJsx.default
                }
                FormElementsInputPlainBaseLgInvalid={
                  activeTabs[0].active_tab === 1
                    ? FormElementsInputPlainBaseLgInvalidHTML.default
                    : FormElementsInputPlainBaseLgInvalidJsx.default
                }
                FormElementsInputPlainBaseRequired={
                  activeTabs[0].active_tab === 1
                    ? FormElementsInputPlainBaseRequiredHTML.default
                    : FormElementsInputPlainBaseRequiredJsx.default
                }
                FormElementsInputPlainBaseLgRequired={
                  activeTabs[0].active_tab === 1
                    ? FormElementsInputPlainBaseLgRequiredHTML.default
                    : FormElementsInputPlainBaseLgRequiredJsx.default
                }
                FormElementsInputPlainBaseDisabled={
                  activeTabs[0].active_tab === 1
                    ? FormElementsInputPlainBaseDisabledHTML.default
                    : FormElementsInputPlainBaseDisabledJsx.default
                }
                FormElementsInputPlainBaseLgDisabled={
                  activeTabs[0].active_tab === 1
                    ? FormElementsInputPlainBaseLgDisabledHTML.default
                    : FormElementsInputPlainBaseLgDisabledJsx.default
                }
                FormElementsInputPlainBaseSearch={
                  activeTabs[0].active_tab === 1
                    ? FormElementsInputPlainBaseSearchHTML.default
                    : FormElementsInputPlainBaseSearchJsx.default
                }
                FormElementsInputPlainBaseLgSearch={
                  activeTabs[0].active_tab === 1
                    ? FormElementsInputPlainBaseLgSearchHTML.default
                    : FormElementsInputPlainBaseLgSearchJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Input Outlined Section */}
          <h3 id="FormElementsInputAllRounded">Outline Input</h3>

          <PreviewBlock
            id="FormElementsInputAllRounded"
            HtmlComponent={FormElementsInputRoundedBaseBasicHTML.default}
            JsxComponent={FormElementsInputRoundedBaseBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <FormElementsInputAllRoundedPreview
                FormElementsInputRoundedBaseBasic={
                  activeTabs[1].active_tab === 1
                    ? FormElementsInputRoundedBaseBasicHTML.default
                    : FormElementsInputRoundedBaseBasicJsx.default
                }
                FormElementsInputRoundedBaseLgBasic={
                  activeTabs[1].active_tab === 1
                    ? FormElementsInputRoundedBaseLgBasicHTML.default
                    : FormElementsInputRoundedBaseLgBasicJsx.default
                }
                FormElementsInputRoundedBaseHelperText={
                  activeTabs[1].active_tab === 1
                    ? FormElementsInputRoundedBaseHelperTextHTML.default
                    : FormElementsInputRoundedBaseHelperTextJsx.default
                }
                FormElementsInputRoundedBaseLgHelperText={
                  activeTabs[1].active_tab === 1
                    ? FormElementsInputRoundedBaseLgHelperTextHTML.default
                    : FormElementsInputRoundedBaseLgHelperTextJsx.default
                }
                FormElementsInputRoundedBaseLeadingIcon={
                  activeTabs[1].active_tab === 1
                    ? FormElementsInputRoundedBaseLeadingIconHTML.default
                    : FormElementsInputRoundedBaseLeadingIconJsx.default
                }
                FormElementsInputRoundedBaseLgLeadingIcon={
                  activeTabs[1].active_tab === 1
                    ? FormElementsInputRoundedBaseLgLeadingIconHTML.default
                    : FormElementsInputRoundedBaseLgLeadingIconJsx.default
                }
                FormElementsInputRoundedBaseTrailingIcon={
                  activeTabs[1].active_tab === 1
                    ? FormElementsInputRoundedBaseTrailingIconHTML.default
                    : FormElementsInputRoundedBaseTrailingIconJsx.default
                }
                FormElementsInputRoundedBaseLgTrailingIcon={
                  activeTabs[1].active_tab === 1
                    ? FormElementsInputRoundedBaseLgTrailingIconHTML.default
                    : FormElementsInputRoundedBaseLgTrailingIconJsx.default
                }
                FormElementsInputRoundedBaseInvalid={
                  activeTabs[1].active_tab === 1
                    ? FormElementsInputRoundedBaseInvalidHTML.default
                    : FormElementsInputRoundedBaseInvalidJsx.default
                }
                FormElementsInputRoundedBaseLgInvalid={
                  activeTabs[1].active_tab === 1
                    ? FormElementsInputRoundedBaseLgInvalidHTML.default
                    : FormElementsInputRoundedBaseLgInvalidJsx.default
                }
                FormElementsInputRoundedBaseRequired={
                  activeTabs[1].active_tab === 1
                    ? FormElementsInputRoundedBaseRequiredHTML.default
                    : FormElementsInputRoundedBaseRequiredJsx.default
                }
                FormElementsInputRoundedBaseLgRequired={
                  activeTabs[1].active_tab === 1
                    ? FormElementsInputRoundedBaseLgRequiredHTML.default
                    : FormElementsInputRoundedBaseLgRequiredJsx.default
                }
                FormElementsInputRoundedBaseDisabled={
                  activeTabs[1].active_tab === 1
                    ? FormElementsInputRoundedBaseDisabledHTML.default
                    : FormElementsInputRoundedBaseDisabledJsx.default
                }
                FormElementsInputRoundedBaseLgDisabled={
                  activeTabs[1].active_tab === 1
                    ? FormElementsInputRoundedBaseLgDisabledHTML.default
                    : FormElementsInputRoundedBaseLgDisabledJsx.default
                }
                FormElementsInputRoundedBaseSearch={
                  activeTabs[1].active_tab === 1
                    ? FormElementsInputRoundedBaseSearchHTML.default
                    : FormElementsInputRoundedBaseSearchJsx.default
                }
                FormElementsInputRoundedBaseLgSearch={
                  activeTabs[1].active_tab === 1
                    ? FormElementsInputRoundedBaseLgSearchHTML.default
                    : FormElementsInputRoundedBaseLgSearchJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>
        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            The <code>&lt;input&gt;</code> fields allow users to enter text into
            a UI. They typically appear in forms and dialogs.
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Input fields come in two basic styles (<strong>base</strong> and{" "}
            <strong>outlined</strong>) and 2 sizes:
          </p>
          <ul>
            <li>
              <strong>Base:</strong> text font size of 0.875rem (14px) and a
              label that scales from 0.875rem (14px) in font size, when the
              input is empty or 0.75rem (12px) when the label is floated. Helper
              text is also 0.75rem (12px) and a top and bottom margin of 1.5rem
              (24px) is used to house both the floating label and the helper
              text.
            </li>
            <li>
              <strong>Large:</strong> text font size of 1rem (16px) and a label
              that scales from 1rem (16px) in font size, when the input is empty
              or 0.75rem (12px) when the label is floated. Helper text is also
              0.75rem (12px) and a top and bottom margin of 1.5rem (24px) is
              used to house both the floating label and the helper text.
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            For better accessibility when using the <code>&lt;input&gt;</code>{" "}
            element:
          </p>
          <ul>
            <li>
              An id attribute to allow the <code>&lt;input&gt;</code> to be
              associated with a <code>&lt;label&gt;</code> element for
              accessibility purposes.
            </li>
            <li>
              A name attribute to set the name of the associated data point
              submitted to the server when the form is submitted.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
